










































































import { Vue, Component, Prop, Watch } from 'vue-class-decorator'
import { GlobalModule, UserModule } from '@/store'

import { Payload } from 'vue/interfaces'
import { Comment } from 'vue/types'

@Component({
})
export default class CommentsComponent extends Vue {
  @Prop({ default: '' }) readonly title!: string
  @Prop({ default: '0' }) readonly pageID!: string
  @Prop({ default: 'price' }) readonly essence!: string
  @Prop({
    default: () => {
      return []
    },
    required: true
  }) value!: Array<Comment>

  public readonly h2: string
  public comments: Array<Comment>
  public newCommnet: string
  public essenceID: string

  constructor() {
    super()
    this.h2 = this.title
    this.comments = this.value
    this.newCommnet = ''
    this.essenceID = this.pageID
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @UserModule.Action('deleteComment') deleteCommentById!: (id: string | number) => Promise<void>
  @UserModule.Action('createComment') createComment!: (payload: Payload) => Promise<any>

  @Watch('value') renderCommentComponent() {
    this.clearMessage()

    if (this.comments.length === 0) {
      this.comments = this.value
    }
  }

  @Watch('comments') createCommentInServer() {
    let findIndex = 0
    const findComment = this.comments.find((comment, index) => {
      if (comment.comment_id === 0) {
        findIndex = index
        return comment
      }
    })

    if (!findComment) return false

    const payload: Payload = {
      id: this.essenceID,
      essence: this.essence,
      comment: findComment
    }

    this.createComment(payload)
      .then((res) => {
        this.comments.splice(findIndex, 1)
        this.comments.push(res.additional.comment)
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  clearMessage() {
    this.newCommnet = ''
  }

  handleInput() {
    if (this.newCommnet.trim() === '' || this.loading) {
      return false
    }
    /* eslint-disable @typescript-eslint/camelcase */
    const payload = {
      comment_id: 0,
      text: this.newCommnet,
      user_name: null,
      user_image: null,
      publish_date: null
    } as Comment
    /* eslint-enable @typescript-eslint/camelcase */

    this.comments.push(payload)
    this.$emit('input', this.comments)
  }

  deleteComment(id: string | number) {
    this.deleteCommentById(id)
      .then(() => {
        let findIndex = 0
        const findComment = this.comments.find((comment, index) => {
          if (comment.comment_id === id) {
            findIndex = index
            return comment
          }
        })

        if (!findComment) return false

        this.comments.splice(findIndex, 1)
        this.$emit('input', this.comments)
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }
}
